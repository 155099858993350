import { PATH_HOME, PATH_AUTH } from '../../../routes/paths';

export const LINKS = [
  {
    headline: 'Company',
    children: [
      { name: 'Our Story', path: PATH_HOME.about },
      { name: 'Fences', path: PATH_HOME.ourfences },
      { name: 'Login', path: PATH_AUTH.login },
    ],
  },
  {
    headline: 'Support',
    children: [
      { name: 'Blog', path: PATH_HOME.blogs },
      { name: 'RealityFence EDU', path: PATH_HOME.edfence },
      { name: 'FAQs', path: PATH_HOME.faqs },
      { name: 'AR', path: PATH_HOME.arpage },
    ],
  },
];

export const ITEMS = [
  { name: 'Get Started', path: PATH_HOME.subscription },
  { name: 'Contact', path: PATH_HOME.contact },
  { name: 'Shopper', path: PATH_HOME.shopper },
];
