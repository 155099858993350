import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { PATH_DASHBOARD } from '../../routes/paths';
import { EMAIL_ALREADY_EXIST, GET_USER_SUCCESS } from '../../utils/messages';

const initialState = {
  isloading: false,
  error: null,
  user: null,
  users: [],
  companies: [],
  updatePaymentInfo: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isloading = true;
    },

    finishLoading(state) {
      state.isloading = false;
    },

    hasError(state, action) {
      state.isloading = false;
      state.error = action.payload;
    },

    setUpdatePaymentInfo(state, action) {
      state.updatePaymentInfo = action.payload;
    },

    getdeleteUsersSuccess(state, action) {
      state.isloading = false;
      state.companies = action.payload;
    },
  },
});

export default slice.reducer;

export const { hasError, setUpdatePaymentInfo } = slice.actions;

export const emailExistCheck = (email, setError) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`/user/existEmailCheck`, { email });
    if (response.data.data.isExist) {
      setError(EMAIL_ALREADY_EXIST);
    } else {
      setError('');
    }
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const emailDuplicationCheck = (email, callback) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`/user/existEmailCheck`, { email });
    callback(response.data.data.isExist);
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const updateFences = (id, userData, handleNext) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    await axios.put(`/user/updateFences/${id}`, userData);
    handleNext();
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const createUser = (userData, navigate) => async (dispatch) => {
  try {
    await axios.post('/user', { data: userData });
    // dispatch(getUsers());
    navigate(PATH_DASHBOARD.user.list);
  } catch (err) {
    dispatch(slice.actions.hasError(err));
  }
};

export const updateUser = (id, userData, Snackbar) => async (dispatch) => {
  try {
    const response = await axios.put(`/user/${id}`, userData);
    switch (response.data.code) {
      case 200:
        dispatch({ type: GET_USER_SUCCESS, payload: userData });
        Snackbar('Succesfully updated!', 'success');
        break;
      case 407:
        Snackbar('Email already exists', 'error');
        break;
      default:
        break;
    }
    dispatch(slice.actions.finishLoading());
  } catch (err) {
    dispatch(slice.actions.hasError(err));
  }
};

export const updateUserAccount = (id, updateData, Snackbar) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.put(`/user/info/${id}`, updateData);
    switch (response.data.code) {
      case 200:
        Snackbar('the user has been updated successfully', 'success');
        window.location.href = PATH_DASHBOARD.account;
        break;
      case 407:
        Snackbar('email already exists!', 'error');
        break;
      case 411:
        Snackbar('current password is incorrect', 'error');
        break;
      default:
        break;
    }
    dispatch(slice.actions.finishLoading());
  } catch (err) {
    dispatch(slice.actions.hasError(err));
  }
};

export const addChildUsers = (id, emailList, SnackBar) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`user/childs/addByParentId/${id}`, { emailList });
    if (response.data.code !== 200) {
      SnackBar('Internal Server Error', 'error');
    }
    SnackBar('Successfully Added!', 'success');
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const deleteUserByEmail = (email, SnackBar) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`user/childs/deleteByEmail`, { email });
    if (response.data.code === 200) SnackBar('the user has beed deleted successfully!', 'success');
    else {
      SnackBar(response.data.message, 'error');
    }
  } catch (err) {
    dispatch(slice.actions.hasError(err));
  }
};

export const updatePlan = (id, userData, navigate) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    await axios.put(`/user/${id}`, userData);
    navigate(PATH_DASHBOARD.planupdatesuccess);
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const setUpgradePaymentInfo = (data) => async (dispatch) => {
  try {
    dispatch(setUpdatePaymentInfo(data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
