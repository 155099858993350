import heic2any from 'heic2any';
import axios from './axios';
import { AWS_S3_BUCKET } from '../config-global';

export const UploadFiles = async (folder, files) => {
  if (files && files.length > 0) {
    const urlList = await Promise.all(
      files.map(async (file, index) => {
        let key = '';
        const fileType = file.path.split('.')[file.path.split('.').length - 1];
        if (file.type === 'image/heic') {
          file = await heic2any({
            blob: file,
            toType: 'image/jpeg',
            quality: 1,
          });

          key = `${folder + Date.now().toString()}-${index}.jpg`;
        } else {
          key = `${folder + Date.now().toString()}-${index}.${fileType}`;
        }

        const preSignedURL = await axios.post('/auth/presignedUrl', { key });
        const myHeaders = new Headers({
          'Content-Type': file.type,
        });
        await fetch(preSignedURL.data.signedUrl, {
          method: 'PUT',
          headers: myHeaders,
          body: file,
        });
        return {
          preview: `https://${AWS_S3_BUCKET}.s3.us-east-1.amazonaws.com/${key}`,
          key,
        };
      })
    );
    return urlList;
  }
  return [];
};
