import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
// ----------------------------------------------------------------------
// Auth
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const SetPasswordPage = Loadable(lazy(() => import('../pages/auth/SetPasswordPage')));
export const ForgotPasswordPage = Loadable(lazy(() => import('../pages/auth/ForgotPasswordPage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const ResetPasswordVerifyPage = Loadable(
  lazy(() => import('../pages/auth/ResetPasswordVerifyPage'))
);
// Onboarding
export const WelcomePage = Loadable(lazy(() => import('../pages/onboarding/WelcomePage')));
export const FenceListPage = Loadable(lazy(() => import('../pages/onboarding/FenceListPage')));
export const FenceDetailPage = Loadable(lazy(() => import('../pages/onboarding/FenceDetailPage')));
export const DownloadAppPage = Loadable(lazy(() => import('../pages/onboarding/DownloadAppPage')));
export const TutorialPage = Loadable(lazy(() => import('../pages/onboarding/TutorialPage')));
// Home
export const HomePage = Loadable(lazy(() => import('../pages/home/HomePage')));
export const SubscriptionPage = Loadable(lazy(() => import('../pages/home/SubscriptionPage')));
export const BlogDetailPage = Loadable(lazy(() => import('../pages/home/BlogDetailPage')));
export const BlogsPage = Loadable(lazy(() => import('../pages/home/BlogsPage')));
export const CheckoutPage = Loadable(lazy(() => import('../pages/home/CheckoutPage')));
export const ContactUsPage = Loadable(lazy(() => import('../pages/home/ContactUsPage')));
export const DownloadPage = Loadable(lazy(() => import('../pages/home/DownloadPage')));
export const EDFencePage = Loadable(lazy(() => import('../pages/home/EDFencePage')));
export const FaqsPage = Loadable(lazy(() => import('../pages/home/FaqsPage')));
export const OurFenceDetailPage = Loadable(lazy(() => import('../pages/home/OurFenceDetailPage')));
export const OurFencesPage = Loadable(lazy(() => import('../pages/home/OurFencesPage')));
export const OurStoryPage = Loadable(lazy(() => import('../pages/home/OurStoryPage')));
export const RealityARPage = Loadable(lazy(() => import('../pages/home/RealityARPage')));
export const MeetingPage = Loadable(lazy(() => import('../pages/home/MeetingPage')));
export const ShopperPage = Loadable(lazy(() => import('../pages/home/ShopperPage')));

// Dashboard
// Account
export const AccountPage = Loadable(lazy(() => import('../pages/dashboard/AccountPage')));

export const ChooseNewPlanPage = Loadable(
  lazy(() => import('../pages/dashboard/account/ChooseNewPlanPage'))
);
export const AlmostDonePage = Loadable(
  lazy(() => import('../pages/dashboard/account/AlmostDonePage'))
);

export const SwitchBillingPage = Loadable(
  lazy(() => import('../pages/dashboard/account/SwitchBillingPage'))
);

export const UpdatePaymentMethodPage = Loadable(
  lazy(() => import('../pages/dashboard/account/UpdatePaymentMethodPage'))
);

export const UpdateAccountPage = Loadable(
  lazy(() => import('../pages/dashboard/account/UpdateAccountPage'))
);

export const UpdateChildUsersPage = Loadable(
  lazy(() => import('../pages/dashboard/account/UpdateChildUsersPage'))
);

export const ConfirmCancelSubscriptionPage = Loadable(
  lazy(() => import('../pages/dashboard/account/ConfirmCancelSubscriptionPage'))
);
// Billing

export const BillingHistoryPage = Loadable(
  lazy(() => import('../pages/dashboard/billing/BillingHistoryPage'))
);
export const BillingDetailPage = Loadable(
  lazy(() => import('../pages/dashboard/billing/billingDetail/BillingDetailsPage'))
);
export const UserBlogsPage = Loadable(lazy(() => import('../pages/dashboard/blog/BlogsPage')));
export const UserBlogDetailPage = Loadable(
  lazy(() => import('../pages/dashboard/blog/BlogDetailPage'))
);
export const UserSupportPage = Loadable(
  lazy(() => import('../pages/dashboard/support/SupportPage'))
);
export const UserContactPage = Loadable(
  lazy(() => import('../pages/dashboard/contact/ContactPage'))
);
export const UserDownloadPage = Loadable(lazy(() => import('../pages/dashboard/DownloadPage')));
export const RecommendedDevicePage = Loadable(
  lazy(() => import('../pages/dashboard/RecommendedDevicePage'))
);
// Congratulations Pages
export const PlanUpdateSuccessPage = Loadable(
  lazy(() => import('../pages/dashboard/account/congratulations/PlanUpdateSuccessPage'))
);
export const SwitchBillingSuccessPage = Loadable(
  lazy(() => import('../pages/dashboard/account/congratulations/SwitchBillingSuccessPage'))
);
export const UpdatePaymentMethodSuccessPage = Loadable(
  lazy(() => import('../pages/dashboard/account/congratulations/UpdatePaymentMethodSuccessPage'))
);
export const PauseSubscriptionSuccessPage = Loadable(
  lazy(() => import('../pages/dashboard/account/congratulations/PauseSubscriptionSuccessPage'))
);
export const CancelSubscriptionSuccessPage = Loadable(
  lazy(() => import('../pages/dashboard/account/congratulations/CancelSubscriptionSuccessPage'))
);
export const ResumeSubscriptionSuccessPage = Loadable(
  lazy(() => import('../pages/dashboard/account/congratulations/ResumeSubscriptionSuccessPage'))
);
// Manage My Fence
export const FenceAddPage = Loadable(lazy(() => import('../pages/managefence/FenceAddPage')));
export const FenceDetailViewPage = Loadable(
  lazy(() => import('../pages/managefence/FenceDetailPage'))
);
export const FenceRemovePage = Loadable(lazy(() => import('../pages/managefence/FenceRemovePage')));
export const FenceRequestPage = Loadable(
  lazy(() => import('../pages/managefence/FenceRequestPage'))
);
// Error Pages
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
