import axios from 'axios';
// utils
import localStorageAvailable from './localStorageAvailable';
// config
import { HOST_API_KEY } from '../config-global';

// ----------------------------------------------------------------------
const storageAvailable = localStorageAvailable();
const axiosInstance = axios.create({ baseURL: HOST_API_KEY });
const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
axiosInstance.interceptors.response.use(
  (response) => {
    if (
      accessToken &&
      (response.data.code === 401 ||
        response.data.code === 403 ||
        response.data.code === 404 ||
        response.data.code === 414)
    ) {
      window.dispatchEvent(new CustomEvent('unauthorized'));
    }
    return response;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
