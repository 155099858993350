import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// routes

const initialState = {
  isloading: false,
  error: null,
  QA: null,
  QAs: [],
};

const slice = createSlice({
  name: 'qa',
  initialState,
  reducers: {
    startLoading(state) {
      state.isloading = true;
    },

    hasError(state, action) {
      state.isloading = false;
      state.error = action.payload;
    },

    getQAsSuccess(state, action) {
      state.isloading = false;
      state.QAs = action.payload;
    },

    getQASuccess(state, action) {
      state.isloading = false;
      state.QA = action.payload;
    },
  },
});

export default slice.reducer;

export const { getQAsSuccess, getQASuccess } = slice.actions;

export const getQAs = () => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get('/qa');
    dispatch(slice.actions.getQAsSuccess(response.data.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const getQA = (id) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(`/qa/${id}`);
    dispatch(slice.actions.getQASuccess(response.data.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
