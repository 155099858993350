import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { Stack, Box } from '@mui/material';
// hooks
import Header from './header';
import Toolbar from './toolbar';

// ----------------------------------------------------------------------

export default function OnboardingLayout() {
  const ToolbarShowURLs = ['/onboarding/fence'];
  const { pathname } = useLocation();
  return (
    <Stack flexDirection="column" width="100%">
      <Header
        sx={{
          px: { lg: 4 },
          py: 2,
          width: '100%',
          top: 0,
          zIndex: 10,
          backgroundColor: 'white',
        }}
      />
      {(ToolbarShowURLs.indexOf(pathname) > -1 || pathname.indexOf('/onboarding/fence/') > -1) && (
        <Toolbar
          sx={{
            width: '100%',
            top: { xs: 50, lg: 100 },
            zIndex: 10,
            px: { lg: 4 },
          }}
        />
      )}

      <Box
        flexDirection="row"
        sx={{
          zIndex: 0,
          px: { lg: 4 },
          width: '100%',
        }}
      >
        <Outlet />
      </Box>
    </Stack>
  );
}
