import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

RFButton.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
};

export function RFButton({ sx, children, ...other }) {
  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: '#1FA9FF !important',
        color: 'white',
        fontSize: '18px',
        letterSpacing: '1px',
        fontFamily: 'Poppins',
        fontWeight: 700,
        borderRadius: '12px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        textAlign: 'center',
        ...sx,
      }}
      {...other}
    >
      {children}
    </Button>
  );
}

RFLoadingButton.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
  loading: PropTypes.bool,
};

export function RFLoadingButton({ sx, loading = false, children, ...other }) {
  return (
    <LoadingButton
      variant="contained"
      loading={loading}
      sx={{
        backgroundColor: '#1FA9FF !important',
        color: 'white',
        fontSize: '18px',
        letterSpacing: '1px',
        fontFamily: 'Poppins',
        fontWeight: 700,
        borderRadius: '12px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        textAlign: 'center',
        ...sx,
      }}
      {...other}
    >
      {children}
    </LoadingButton>
  );
}

RFSecondaryButton.propTypes = {
  loading: PropTypes.bool,
  sx: PropTypes.object,
  children: PropTypes.node,
};
export function RFSecondaryButton({ sx, loading = false, children, ...other }) {
  return (
    <LoadingButton
      variant="text"
      color="inherit"
      loading={loading}
      sx={{
        backgroundColor: '#C0DEFF !important',
        color: '#1FA9FF',
        fontSize: '18px',
        borderRadius: '14px',
        padding: '10px 70px',
        letterSpacing: '1px',
        fontFamily: 'Poppins',
        fontWeight: 700,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        ...sx,
      }}
      {...other}
    >
      {children}
    </LoadingButton>
  );
}
