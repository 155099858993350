import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// routes
import { PATH_HOME } from '../routes/paths';
// components
import LoadingScreen from '../components/loading-screen';
import { ALLOWED_SUBSCRIPTION_STATUS } from '../utils/constants';
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

OnboardingGuard.propTypes = {
  children: PropTypes.node,
};

export default function OnboardingGuard({ children }) {
  const { user, isInitialized } = useAuthContext();
  if (!isInitialized) {
    return <LoadingScreen />;
  }
  if (!user || (user && ALLOWED_SUBSCRIPTION_STATUS.indexOf(user.subscription_status) < 0)) {
    return (
      <Navigate
        to={
          localStorage.getItem('currentSubscriptionId')
            ? PATH_HOME.checkout
            : PATH_HOME.subscription
        }
      />
    );
  }

  return <> {children} </>;
}
