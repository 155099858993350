export const UserRoles = {
  admin: 0,
  company: 1,
  shopper: 2,
};

export const LoginTypes = {
  general: 0,
  social: 1,
};

export const UserType = {
  Pro: 1,
  'Pro+': 2,
  Enterprise: 3,
  //   'Free Trial': 4,
  Basic: 5,
};

export const EMAIL_TYPE = {
  SIGN_UP_VERIFY_EMAIL: 'signup_verify_email',
  SIGNUP_EMAIL: 'signup_email',
  UPGRADE_SUBSCRIPTION: 'upgrade_subscription',
  DOWNGRADE_SUBSCRIPTION: 'downgrade_subscription',
};

export const VERIFY_TYPE = {
  SIGN_UP_VERIFY: 'singup_verify',
  RESET_PASSWORD_VERIFY: 'reset_password_verify',
};

export const INTEREST_LEVEL = {
  LOOKING: 'Ready to buy',
  BUY: 'Just looking',
};

export const Selectable = {
  Fence: 1,
  Category: 2,
  Detail: 3,
};

export const FenceViewMode = {
  TILE: 2,
  LIST: 1,
};

export const FENCE_STATUS = {
  NOT_STARTED: '1',
  PENDING: '2',
  COMPLETE: '3',
  MODELING: '4',
};

export const ALLOWED_SUBSCRIPTION_STATUS = ['active', 'trialing'];

export const NOT_ALLOWED_SUBSCRIPTION_STATUS = ['canceled', 'trialing'];

export const SUBSCRIPTION_STATUS = {
  TRIAL: 'trialing',
  ACTIVE: 'active',
  PAUSE: 'paused',
  CANCELLED: 'canceled',
  NOSUBSCRIPTION: 'No Subscription',
};

export const PAYMENT_TYPE = {
  MONTHLY: '0',
  YEARLY: '1',
};

export const STRIPE_PRICE_INTERVAL = {
  MONTH: 'month',
  ANNUAL: 'year',
};

export const S3_PRODUCT_IMAGE_FOLDER = 'products/';
