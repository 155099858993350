/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// @mui
import {
  Stack,
  Typography,
  useMediaQuery,
  Dialog,
  DialogTitle,
  Box,
  CircularProgress,
  circularProgressClasses,
} from '@mui/material';
// components
import { RFButton } from '../../../components/button';
import { useSnackbar } from '../../../components/snackbar';
// context
import { useAuthContext } from '../../../auth/useAuthContext';
// utils
import axios from '../../../utils/axios';
import { UploadFiles } from '../../../utils/uploadFile';
import { FENCE_STATUS, S3_PRODUCT_IMAGE_FOLDER } from '../../../utils/constants';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import { setCurrentUserFenceData, setUserFenceData } from '../../../redux/slices/fence';
import { updateFences } from '../../../redux/slices/user';
// ----------------------------------------------------
Header.propTypes = {
  sx: PropTypes.object,
};

export default function Header({ sx }) {
  const dispatch = useDispatch();
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery('(max-width:756px)');

  const {
    selected_fence_list,
    new_selected_fence_list,
    requested_fence_list,
    new_requested_fence_list,
    removed_fence_list,
  } = useSelector((state) => state.fence);

  const [subscription, setSubscription] = useState({});
  const [saveOpen, setSaveOpen] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  useEffect(() => {
    const fetchStripeSubscription = async () => {
      if (user) {
        const response = await axios.get(`subscription/${user.plan}`);
        setSubscription(response.data.data);
      }
    };
    fetchStripeSubscription();
  }, [user]);

  const handleRequestData = async () => {
    if (new_requested_fence_list.length < 1) {
      return [];
    }
    const requestFencesDataData = await Promise.all(
      new_requested_fence_list.map(async (fence) => {
        const imageUrlList = await UploadFiles(S3_PRODUCT_IMAGE_FOLDER, fence.filesImage);
        return {
          ...fence,
          filesImage: imageUrlList,
          status: FENCE_STATUS.NOT_STARTED,
          addedBy: user.id,
        };
      })
    );
    return requestFencesDataData;
  };

  const handleSaveToDashboard = async () => {
    if (
      selected_fence_list.length +
        new_selected_fence_list.length +
        requested_fence_list.length +
        new_requested_fence_list -
        removed_fence_list.length >
      subscription.totalFences
    ) {
      enqueueSnackbar(
        `You can select only ${subscription.totalFences} Fences. If you want to select more fences, upgrade your subscription.`,
        {
          variant: 'error',
        }
      );
      return;
    }
    handleSaveClose();
    setIsSaveLoading(true);

    let selectedFences_list = [
      ...new Set([
        ...selected_fence_list.map((fence) => {
          if (fence) return fence.id;
        }),
        ...new_selected_fence_list.map((fence) => {
          if (fence) return fence.id;
        }),
      ]),
    ];

    removed_fence_list.forEach((fence) => {
      selectedFences_list = selectedFences_list.filter((item) => item !== fence.id);
    });

    const requestedFences_list = await handleRequestData();
    const updateData = {
      selectedFences: selectedFences_list,
      requestFences: requestedFences_list,
      email: user?.email.toLowerCase(),
    };
    dispatch(
      updateFences(user?.id, updateData, () => {
        window.location.href = PATH_DASHBOARD.account;
      })
    );
    dispatch(setUserFenceData({ selected: [], requested: [], removed: [] }));
    dispatch(setCurrentUserFenceData({ selected: [], requested: [] }));
    setIsSaveLoading(false);
  };
  const handleCancel = () => {
    window.location.href = PATH_DASHBOARD.account;
  };

  const handleSaveOpen = () => {
    setSaveOpen(true);
  };

  const handleSaveClose = () => {
    setSaveOpen(false);
  };

  return (
    <Stack sx={{ ...sx }}>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <RFButton
          style={{
            marginTop: '8px',
            marginBottom: '8px',
            height: '40px',
          }}
          sx={{
            width: { sm: '180px', xs: '150px' },
            fontSize: { sm: '15px', xs: '13px' },
          }}
          onClick={handleCancel}
        >
          Cancel
        </RFButton>
        <RFButton
          className="step8"
          sx={{
            width: { sm: '180px', xs: '150px' },
            fontSize: { sm: '15px', xs: '13px' },
            mt: 1,
            padding: '5px 5px',
            height: '40px',
          }}
          disabled={
            new_selected_fence_list.length +
              new_requested_fence_list.length +
              removed_fence_list.length ===
            0
          }
          onClick={handleSaveOpen}
        >
          Save and Finish
        </RFButton>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          sx={{
            fontSize: { xs: '22px', sm: '26px', md: '30px', lg: '34px' },
            fontWeight: 800,
            color: 'black',
          }}
        >
          Manage My Fences
        </Typography>
        <Stack direction="column" alignItems="flex-end">
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: { xs: '16px', sm: '18px' },
            }}
          >
            My Fences : &nbsp;
            {selected_fence_list.length +
              new_selected_fence_list.length -
              removed_fence_list.length}{' '}
            / {subscription?.totalFences}
          </Typography>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: { xs: '16px', sm: '18px' },
            }}
          >
            Requests : &nbsp;{requested_fence_list.length}
          </Typography>
        </Stack>
      </Stack>
      <Dialog open={saveOpen} onClose={handleSaveClose}>
        <DialogTitle
          sx={{ minWidth: '300px', maxWidth: '900px', paddingBottom: '0' }}
          id="simple-save-dialog-title"
        >
          <Typography
            sx={{
              fontSize: { sm: '20px', xs: '16px' },
              fontWeight: 700,
              paddingRight: { sm: '48px', xs: '0' },
              paddingLeft: { sm: '48px', xs: '0' },
              paddingTop: '10px',
              paddingBottom: '12px',
              lineHeight: 1.1,
            }}
            align="center"
          >
            If you added or removed Fences,
            <br />
            make sure you tap “Sync Data”
            <br />
            in the app menu.
          </Typography>
        </DialogTitle>
        <Stack flexDirection="column" alignItems="center" justifyContent="center" gap={1}>
          <Stack display="flex" flexDirection="row">
            <img
              src="/assets/swap_confirm1.svg"
              alt="swapperconfirm1"
              style={{ width: '210px', paddingRight: '28px', paddingLeft: '28px' }}
            />
            <img
              src="/assets/swap_confirm2.svg"
              alt="swapperconfirm2"
              style={{ width: '210px', paddingRight: '28px', paddingLeft: '28px' }}
            />
          </Stack>
          <Typography
            sx={{
              fontSize: { sm: '20px', xs: '16px' },
              fontWeight: 700,
              paddingRight: { sm: '48px', xs: '0' },
              paddingLeft: { sm: '48px', xs: '0' },
              paddingBottom: '20px',
              lineHeight: 1.1,
            }}
            align="center"
          >
            If you requested Fences, we will
            <br />
            send you a quote shortly!
          </Typography>
          <RFButton
            style={{
              padding: '15px 16px',
              width: '230px',
              height: '42px',
              marginBottom: '4px',
            }}
            onClick={handleSaveClose}
          >
            Cancel
          </RFButton>
          <RFButton
            style={{
              padding: '15px 16px',
              width: '230px',
              height: '42px',
              marginBottom: '38px',
            }}
            onClick={handleSaveToDashboard}
          >
            Save and Finish
          </RFButton>
        </Stack>
      </Dialog>

      {isSaveLoading && (
        <Dialog open>
          <DialogTitle sx={{ minWidth: '300px', maxWidth: '900px', paddingBottom: '0' }}>
            <Typography
              sx={{
                fontSize: isMobile ? '24px' : '40px',
                fontWeight: 700,
                fontFamily: 'Poppins',
                pr: { sm: '48px', xs: '0' },
                pl: { sm: '48px', xs: '0' },
                pt: '10px',
                pb: '12px',
                mb: '20px',
                lineHeight: 1.1,
              }}
              align="center"
            >
              Saving Changes
            </Typography>
          </DialogTitle>
          <Stack
            width={isMobile ? 350 : 550}
            height={isMobile ? 250 : 450}
            sx={{ position: 'relative' }}
          >
            <Box>
              <CircularProgress
                variant="determinate"
                size={isMobile ? 200 : 350}
                thickness={4}
                value={100}
                sx={{ color: '#DADADA', left: isMobile ? 75 : 100, position: 'absolute' }}
              />
              <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{
                  color: '#1a90ff',
                  animationDuration: '2500ms',
                  position: 'absolute',
                  left: isMobile ? 75 : 100,
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                  },
                }}
                size={isMobile ? 200 : 350}
                thickness={4}
              />
            </Box>
            <Box
              sx={{
                top: isMobile ? 30 : 10,
                left: isMobile ? 100 : 150,
                right: isMobile ? 100 : 150,
                bottom: 0,
                height: isMobile ? 150 : 350,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                component="div"
                color="black"
                sx={{
                  fontSize: isMobile ? '16px' : '22px',
                  fontFamily: 'Public Sans',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Please wait, this may take a few minutes.
              </Typography>
            </Box>
          </Stack>
        </Dialog>
      )}
    </Stack>
  );
}
