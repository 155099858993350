import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';

const initialState = {
  isloading: false,
  error: null,
  blog: null,
  blogs: [],
  drafts: [],
};

const slice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    startLoading(state) {
      state.isloading = true;
    },

    hasError(state, action) {
      state.isloading = false;
      state.error = action.payload;
    },

    getBlogsSuccess(state, action) {
      state.isloading = false;
      state.blogs = action.payload;
    },

    getDraftsSuccess(state, action) {
      state.isloading = false;
      state.drafts = action.payload;
    },

    getBlogSuccess(state, action) {
      state.isloading = false;
      state.blog = action.payload;
    },
  },
});

export default slice.reducer;

export const { getBlogsSuccess, getBlogSuccess } = slice.actions;

export const getBlogs = (searchParams) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(`/blog?${searchParams}`);
    dispatch(slice.actions.getBlogsSuccess(response.data.data.blogs));
    dispatch(slice.actions.getDraftsSuccess(response.data.data.drafts));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const getBlog = (id) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(`/blog/${id}`);
    dispatch(slice.actions.getBlogSuccess(response.data.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const addBlog = (blogData, Alert, SnackBar) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post('/blog', blogData);

    if (response.data.code === 201) {
      Alert();
    }
    if (response.data.code === 407) {
      SnackBar('The RealityFence is already exist', 'error');
    }
  } catch (err) {
    dispatch(slice.actions.hasError(err));
  }
};

export const updateBlog = (id, blogData, SnackBar, navigate) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    await axios.put(`blog/${id}`, blogData);

    navigate(PATH_DASHBOARD.general.blog.list);
    SnackBar('Successfully updated!', 'success');
  } catch (err) {
    dispatch(slice.actions.hasError(err));
  }
};

export const deleteBlog = (id, Snackbar) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    await axios.put(`blog/delete/${id}`);

    dispatch(getBlogs());
    Snackbar('Succesfully deleted!', 'success');
  } catch (err) {
    dispatch(slice.actions.hasError(err));
    Snackbar('Server Error', {
      variant: 'error',
    });
  }
};
