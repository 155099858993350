import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
// @mui
import { Stack, Box } from '@mui/material';
// hooks
import Header from './header';
import Toolbar from './toolbar';

// action
import { getCurrnetUserFenceData } from '../../redux/slices/fence';
import { useAuthContext } from '../../auth/useAuthContext';
import { getSubscription } from '../../redux/slices/subscription';
// ----------------------------------------------------------------------

export default function ManageFenceLayout() {
  const { user } = useAuthContext();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrnetUserFenceData(user?.id));
    dispatch(getSubscription(user?.plan));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <Stack flexDirection="column" width="100%">
      <Header
        sx={{
          px: { lg: 4 },
          py: 2,
          width: '100%',
          top: 0,
          zIndex: 10,
          backgroundColor: 'white',
        }}
      />
      <Toolbar
        sx={{
          width: '100%',
          top: { xs: 50, lg: 100 },
          zIndex: 10,
          px: { lg: 4 },
        }}
      />

      <Box
        flexDirection="row"
        sx={{
          zIndex: 0,
          px: { lg: 4 },
          width: '100%',
        }}
      >
        <Outlet />
      </Box>
    </Stack>
  );
}
