import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// routes

const initialState = {
  isloading: false,
  error: null,
  ED: null,
  EDs: [],
  drafts: [],
};

const slice = createSlice({
  name: 'ed',
  initialState,
  reducers: {
    startLoading(state) {
      state.isloading = true;
    },

    hasError(state, action) {
      state.isloading = false;
      state.error = action.payload;
    },

    getEDsSuccess(state, action) {
      state.isloading = false;
      state.EDs = action.payload;
    },

    getDraftsSuccess(state, action) {
      state.isloading = false;
      state.drafts = action.payload;
    },

    getEDSuccess(state, action) {
      state.isloading = false;
      state.ED = action.payload;
    },
  },
});

export default slice.reducer;

export const { getEDsSuccess, getEDSuccess } = slice.actions;

export const getEDs = () => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get('/ed');
    dispatch(slice.actions.getEDsSuccess(response.data.data.eds));
    dispatch(slice.actions.getDraftsSuccess(response.data.data.drafts));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const getED = (id) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(`/ed/${id}`);
    dispatch(slice.actions.getEDSuccess(response.data.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
