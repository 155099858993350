import { useRoutes, Navigate, useLocation } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TourProvider } from '@reactour/tour';
// eslint-disable-next-line import/no-extraneous-dependencies
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Button } from '@mui/material';
// layouts
import HomeLayout from '../layouts/home';
import CompactLayout from '../layouts/compact';
import OnboardingLayout from '../layouts/onboarding';
import DashboardLayout from '../layouts/dashboard/DashboardLayout';
import ManageFenceLayout from '../layouts/managefence';
// Guard
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import SetPasswordGuard from '../auth/SetPasswordGuard';
import OnboardingGuard from '../auth/OnboardingGuard';

import {
  // Auth
  LoginPage,
  SetPasswordPage,
  ForgotPasswordPage,
  NewPasswordPage,
  VerifyCodePage,
  ResetPasswordVerifyPage,
  // Onboarding
  WelcomePage,
  FenceListPage,
  FenceDetailPage,
  DownloadAppPage,
  TutorialPage,
  // Home
  HomePage,
  SubscriptionPage,
  BlogDetailPage,
  BlogsPage,
  CheckoutPage,
  ContactUsPage,
  DownloadPage,
  EDFencePage,
  FaqsPage,
  OurFencesPage,
  OurFenceDetailPage,
  OurStoryPage,
  RealityARPage,
  MeetingPage,
  ShopperPage,
  // Dashboard
  AccountPage,
  ConfirmCancelSubscriptionPage,
  ChooseNewPlanPage,
  AlmostDonePage,
  SwitchBillingPage,
  UpdatePaymentMethodPage,
  UpdateAccountPage,
  UpdateChildUsersPage,
  BillingHistoryPage,
  BillingDetailPage,
  UserBlogsPage,
  UserBlogDetailPage,
  UserSupportPage,
  UserContactPage,
  RecommendedDevicePage,
  UserDownloadPage,
  // Manage My Fence
  FenceAddPage,
  FenceRequestPage,
  FenceRemovePage,
  // Congratulation Pages
  PlanUpdateSuccessPage,
  SwitchBillingSuccessPage,
  UpdatePaymentMethodSuccessPage,
  PauseSubscriptionSuccessPage,
  CancelSubscriptionSuccessPage,
  ResumeSubscriptionSuccessPage,
  // Extra Pages
  MaintenancePage,
  Page403,
  Page404,
  Page500,
  FenceDetailViewPage,
} from './elements';

const Tutorials = [
  {
    id: 1,
    selector: '.step1',
    content: (
      <span>
        Welcome to Fence
        <br /> Requests! Let&apos;s start with <br />a short tutorial.
      </span>
    ),
  },
  {
    id: 2,
    selector: '.step2',
    content: (
      <span>
        First, make sure you only
        <br /> request{' '}
        <span
          style={{
            textDecoration: 'underline',
          }}
        >
          one
        </span>{' '}
        fence at a <br />
        time.
      </span>
    ),
  },
  {
    id: 3,
    selector: '.step3',
    content: (
      <span>
        For each fence, fill out
        <br /> the form with the
        <br /> necessary details.
      </span>
    ),
  },
  {
    id: 4,
    selector: '.step4',
    content: (
      <span>
        Make sure you add several
        <br /> images of the front and
        <br /> back of the fence.
      </span>
    ),
  },
  {
    id: 5,
    selector: '.step5',
    content: (
      <span>
        When you are finished
        <br /> with each Fence Request,
        <br /> click &quot;Add Request.&quot;
      </span>
    ),
  },
  {
    id: 6,
    selector: '.step6',
    content: (
      <span>
        You will see that the
        <br /> request has been added <br /> to the Requests tab.
      </span>
    ),
  },
  {
    id: 7,
    selector: '.step7',
    content: (
      <span>
        You can add as many
        <br /> requests as you need from
        <br /> this screen - at any time!
      </span>
    ),
  },
  {
    id: 8,
    selector: '.step8',
    content: (
      <span>
        When you are finished
        <br /> adding all of your requests,
        <br /> click &quot;Save and Finish.&quot;
      </span>
    ),
  },
];
// ----------------------------------------------------------------------

export default function Router() {
  const { pathname } = useLocation();

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'setPassword/:token',
          element: (
            <SetPasswordGuard>
              <SetPasswordPage />
            </SetPasswordGuard>
          ),
        },
        {
          path: 'forgotPassword',
          element: <ForgotPasswordPage />,
        },
        {
          element: <CompactLayout />,
          children: [
            {
              path: 'new-password',
              element: <NewPasswordPage />,
            },
            { path: 'verify', element: <VerifyCodePage /> },
            { path: 'resetPasswordVerify', element: <ResetPasswordVerifyPage /> },
          ],
        },
      ],
    },
    // Onboarding
    {
      path: 'onboarding',
      element: (
        <OnboardingGuard>
          <OnboardingLayout />
        </OnboardingGuard>
      ),
      children: [
        { path: 'welcome', element: <WelcomePage /> },
        { path: 'fence', element: <FenceListPage /> },
        { path: 'fence/:id', element: <FenceDetailPage /> },
        { path: 'download', element: <DownloadAppPage /> },
        { path: 'tutorial', element: <TutorialPage /> },
      ],
    },
    // Home
    {
      element: <HomeLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: '/subscription', element: <SubscriptionPage /> },
        { path: '/blog/:id', element: <BlogDetailPage /> },
        { path: '/blog', element: <BlogsPage /> },
        { path: '/checkout', element: <CheckoutPage /> },
        { path: '/contactus', element: <ContactUsPage /> },
        { path: '/download', element: <DownloadPage /> },
        { path: '/edfence', element: <EDFencePage /> },
        { path: '/faqs', element: <FaqsPage /> },
        { path: '/ourfences', element: <OurFencesPage /> },
        { path: '/ourfences/:id', element: <OurFenceDetailPage /> },
        { path: '/aboutus', element: <OurStoryPage /> },
        { path: '/ar', element: <RealityARPage /> },
        { path: '/meeting', element: <MeetingPage /> },
        { path: '/shopper', element: <ShopperPage /> },
      ],
    },
    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        // account
        { path: 'account', element: <AccountPage /> },
        { path: 'account/choosenewplan', element: <ChooseNewPlanPage /> },
        { path: 'account/almostdone', element: <AlmostDonePage /> },
        { path: 'account/switchbilling', element: <SwitchBillingPage /> },
        { path: 'account/updatePaymentMethod', element: <UpdatePaymentMethodPage /> },
        { path: 'account/updateaccount', element: <UpdateAccountPage /> },
        { path: 'account/updatechildusers', element: <UpdateChildUsersPage /> },
        { path: 'account/confirmcancelsubscription', element: <ConfirmCancelSubscriptionPage /> },
        // billing
        { path: 'billing', element: <BillingHistoryPage /> },
        { path: 'billing/:id', element: <BillingDetailPage /> },
        { path: 'blog', element: <UserBlogsPage /> },
        { path: 'blog/:id', element: <UserBlogDetailPage /> },
        { path: 'support', element: <UserSupportPage /> },
        { path: 'contactus', element: <UserContactPage /> },
        { path: 'download', element: <UserDownloadPage /> },
        { path: 'recommended', element: <RecommendedDevicePage /> },
        // congratulation pages
        { path: 'account/planupdate/success', element: <PlanUpdateSuccessPage /> },
        { path: 'account/switchbilling/success', element: <SwitchBillingSuccessPage /> },
        {
          path: 'account/updatepaymentmethod/success',
          element: <UpdatePaymentMethodSuccessPage />,
        },
        {
          path: 'account/pausesubscription/success',
          element: <PauseSubscriptionSuccessPage />,
        },
        {
          path: 'account/cancelsubscription/success',
          element: <CancelSubscriptionSuccessPage />,
        },
        {
          path: 'account/resumesubscription/success',
          element: <ResumeSubscriptionSuccessPage />,
        },
      ],
    },
    // fence
    {
      element: (
        <AuthGuard>
          <OnboardingGuard>
            <TourProvider
              scrollSmooth
              steps={Tutorials}
              defaultOpen={pathname === '/fence/request'}
              afterOpen={disableBody}
              beforeClose={enableBody}
              styles={{
                popover: (base) => ({
                  ...base,
                  '--reactour-accent': '#1FA9FF',
                  borderRadius: '10px',
                  maxWidth: '450px',
                  width: '400px',
                  fontSize: '24px',
                  fontFamily: 'Poppins',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  margin: '2px 30px 30px 22px',
                }),
                maskArea: (base) => ({ ...base, rx: '10px', padding: 10 }),
                badge: (base) => ({
                  ...base,
                  right: 'auto',
                  left: '-0.8125em',
                }),
                close: (base) => ({
                  ...base,
                  left: 'auto',
                  right: 8,
                  top: 8,
                  width: 12,
                  height: 12,
                }),
              }}
              prevButton={({ currentStep, setCurrentStep }) => {
                const first = currentStep === 0;
                return first ? (
                  <div
                    style={{
                      width: '50px',
                    }}
                  />
                ) : (
                  <Button
                    sx={{
                      backgroundColor: '#1FA9FF !important',
                      color: 'white',
                      width: '50px',
                      textAlign: 'center',
                    }}
                    variant="contained"
                    onClick={() => {
                      setCurrentStep((s) => s - 1);
                    }}
                  >
                    Back
                  </Button>
                );
              }}
              nextButton={({ currentStep, stepsLength, setIsOpen, setCurrentStep }) => {
                const last = currentStep === stepsLength - 1;
                return (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#1FA9FF !important',
                      color: 'white',
                      width: '50px',
                      textAlign: 'center',
                    }}
                    onClick={() => {
                      if (last) {
                        setIsOpen(false);
                      } else {
                        setCurrentStep((s) => (s === Tutorials.length - 1 ? 0 : s + 1));
                      }
                    }}
                  >
                    {last ? 'Close' : 'Next'}
                  </Button>
                );
              }}
            >
              <ManageFenceLayout />
            </TourProvider>
          </OnboardingGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: '/fence/add',
          element: <FenceAddPage />,
        },
        { path: '/fence/remove', element: <FenceRemovePage /> },
        { path: '/fence/request', element: <FenceRequestPage /> },
        {
          path: '/fence/view/:id',
          element: <FenceDetailViewPage />,
        },
      ],
    },
    // Error
    {
      element: <CompactLayout />,
      children: [
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
