import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
// @mui
import TaskAlt from '@mui/icons-material/TaskAlt';

import { Button, Badge, Typography, Popover, Box, Grid, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { paramCase } from 'change-case';
import Image from '../../../components/image';
import { updateFences } from '../../../redux/slices/user';
// hooks
import useResponsive from '../../../hooks/useResponsive';
//
import Select from '../../../assets/images/onboarding/select.svg';
import Trash from '../../../assets/images/onboarding/trash.png';
import { PATH_ONBOARDING } from '../../../routes/paths';
import { setUserFenceData } from '../../../redux/slices/fence';
import { RFLoadingButton } from '../../../components/button';
import { getSubscription } from '../../../redux/slices/subscription';
import { useAuthContext } from '../../../auth/useAuthContext';
// ----------------------------------------------------------------------

ToolBar.propTypes = {
  sx: PropTypes.object,
};

export default function ToolBar({ sx }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [click, setClick] = useState(false);
  const { user } = useAuthContext();
  const { subscription } = useSelector((state) => state.subscription);
  const { new_selected_fence_list } = useSelector((state) => state.fence);
  const [available, setAvailable] = useState(30);
  const [popOverData, setPopOverData] = useState({
    title: 'My Fences',
    fences: new_selected_fence_list,
  });
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useResponsive('down', 'lg');

  const handleClick = (event) => {
    setPopOverData({ ...popOverData, fences: new_selected_fence_list });
    setClick(event.currentTarget);
  };

  const handleProverClose = () => {
    setClick(null);
  };

  const handleConfirm = () => {
    setIsLoading(false);
    navigate(PATH_ONBOARDING.download);
  };

  const handleItemDelete = (itemId) => {
    const fenceData = popOverData.fences.filter((item) => item.id !== itemId);
    setPopOverData({ ...popOverData, fences: fenceData });
    dispatch(setUserFenceData({ selected: fenceData }));
  };

  const handleClickToolbar = (id) => {
    navigate(PATH_ONBOARDING.onboarding.viewDetail(paramCase(id)));
  };

  const onHandleSaveAndContinue = () => {
    setIsLoading(true);
    const updateData = {
      selectedFences: new_selected_fence_list.map((fence) => fence.id),
      requestFences: [],
      email: user?.email,
    };
    dispatch(updateFences(user?.id, updateData, handleConfirm));
  };

  useEffect(() => {
    if (user) {
      dispatch(getSubscription(user.plan));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (subscription) {
      setAvailable(subscription.totalFences);
    }
  }, [subscription]);

  const renderContent = (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        gap: 1.2,
        p: '10px 7px',
        backgroundColor: '#F5F5F5',
      }}
    >
      <RFLoadingButton
        variant="contained"
        loading={isLoading}
        sx={{
          padding: '15px',
          marginTop: '10px',
          marginBottom: '10px',
          fontSize: 14,
        }}
        onClick={onHandleSaveAndContinue}
      >
        {isMobile ? <TaskAlt /> : 'Save And Continue'}
      </RFLoadingButton>
      {!isMobile && (
        <Typography
          sx={{
            fontSize: { xs: '24px', sm: '28px', md: '36px' },
            fontWeight: 800,
            color: 'black',
          }}
        >
          Fence Selector
        </Typography>
      )}
      <Stack flexDirection="row" sx={{ alignItems: 'center', mt: '5px' }}>
        &nbsp;&nbsp;
        <Stack direction="column" alignItems="flex-end">
          <Typography
            sx={{ color: 'black', fontWeight: 'bold', fontSize: { xs: '20px', lg: '24px' } }}
          >
            Fences Selected: &nbsp;
            {new_selected_fence_list.length}
          </Typography>
          <Typography sx={{ fontSize: { xs: '18px', lg: '20px' }, color: 'black' }}>
            Available : {available}
          </Typography>
        </Stack>
        &nbsp;&nbsp;
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button
            style={{
              backgroundColor: '#FFFFFF',
              height: '50px',
              borderRadius: '14px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
            }}
            onClick={(event) => {
              handleClick(event);
            }}
          >
            <Badge badgeContent={new_selected_fence_list.length} color="error">
              <img src={Select} alt="select" />
            </Badge>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <Stack sx={{ ...sx }}>
      {renderContent}

      <Popover
        open={Boolean(click)}
        anchorEl={click}
        onClose={handleProverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ p: 2, width: 400 }}>
          <div className="flex justify-between">
            <div>{}</div>
            <Typography variant="h5" gutterBottom>
              {popOverData.title}
            </Typography>
            <Badge badgeContent={popOverData.fences.length} color="error">
              <img src={Select} className="w-[20px] h-[20px]" alt="favour" />
            </Badge>
          </div>

          {Array.isArray(popOverData?.fences) &&
            popOverData.fences.map((fence, index) => (
              <Box
                style={{
                  boxShadow: '0px 6px 9px #a6a6a6',
                  alignSelf: 'center',
                  marginBottom: '10px',
                }}
                sx={{ px: 1.5, py: 2 }}
                key={fence && fence.id ? fence.id : index}
              >
                <Grid
                  container
                  item
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignSelf: 'center',
                  }}
                >
                  <Grid item xs={9.6}>
                    <Grid
                      item
                      container
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignSelf: 'center',
                      }}
                    >
                      <Grid item xs={3.5} sx={{ alignSelf: 'center' }}>
                        <Image
                          src={
                            fence &&
                            fence.filesImage &&
                            fence.filesImage.length > 0 &&
                            fence.filesImage[0].preview
                          }
                          sx={{ width: '78px', cursor: 'pointer' }}
                          alt="favour"
                          onClick={() => handleClickToolbar(fence && fence.id)}
                        />
                      </Grid>
                      <Grid item xs={8} sx={{ alignSelf: 'center' }}>
                        <div
                          className="col"
                          style={{
                            '& > *': {
                              color: 'black',
                            },
                          }}
                        >
                          <Typography sx={{ fontsize: '16px', fontWeight: 700 }}>
                            {fence?.name}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} sx={{ alignSelf: 'center' }}>
                    <Button onClick={() => handleItemDelete(fence.id)}>
                      <img src={Trash} className="w-[20px] h-[20px] ml-3" alt="trash" />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ))}
        </Box>
      </Popover>
    </Stack>
  );
}
