// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ONBOARDING = '/onboarding';
const ROOTS_MANAGEFENCE = '/fence';

export const PATH_HOME = {
  checkout: '/checkout',
  subscription: '/subscription',
  about: '/aboutus',
  faqs: '/faqs',
  arpage: '/ar',
  edfence: '/edfence',
  blogs: '/blog',
  blogDetail: (id) => `/blog/${id}`,
  contact: '/contactus',
  meeting: '/meeting',
  shopper: '/shopper',
  ourfences: '/ourfences',
  ourfenceDetail: (id) => `/ourfences/${id}`,
  getStarted: '/getStarted',
  download: '/download',
};
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  forgotPassword: path(ROOTS_AUTH, '/forgotPassword'),
  setPassword: path(ROOTS_AUTH, '/setPassword'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  resetPasswordVerify: path(ROOTS_AUTH, '/resetPasswordVerify'),
};
export const PATH_DASHBOARD = {
  // accounts
  account: path(ROOTS_DASHBOARD, '/account'),
  chooseNewPlan: path(ROOTS_DASHBOARD, '/account/choosenewplan'),
  almostDone: path(ROOTS_DASHBOARD, '/account/almostdone'),
  switchBilling: path(ROOTS_DASHBOARD, '/account/switchbilling'),
  updatePaymentMethod: path(ROOTS_DASHBOARD, '/account/updatepaymentmethod'),
  updateAccount: path(ROOTS_DASHBOARD, '/account/updateaccount'),
  updateChildUsers: path(ROOTS_DASHBOARD, '/account/updatechildusers'),
  callForCancel: path(ROOTS_DASHBOARD, '/account/confirmcancelsubscription'),
  // billings
  billing: path(ROOTS_DASHBOARD, '/billing'),
  billingDetail: (id) => path(ROOTS_DASHBOARD, `/billing/${id}`),
  blog: path(ROOTS_DASHBOARD, '/blog'),
  blogDetail: (id) => path(ROOTS_DASHBOARD, `/blog/${id}`),
  support: path(ROOTS_DASHBOARD, '/support'),
  contactus: path(ROOTS_DASHBOARD, '/contactus'),
  download: path(ROOTS_DASHBOARD, '/download'),
  recommended: path(ROOTS_DASHBOARD, '/recommended'),
  // congratulations pages
  planupdatesuccess: path(ROOTS_DASHBOARD, '/account/planupdate/success'),
  switchbillingsuccess: path(ROOTS_DASHBOARD, '/account/switchbilling/success'),
  paymentmethodupdatesuccess: path(ROOTS_DASHBOARD, '/account/updatepaymentmethod/success'),
  pausesubscriptionsuccess: path(ROOTS_DASHBOARD, '/account/pausesubscription/success'),
  cancelsubscriptionsuccess: path(ROOTS_DASHBOARD, '/account/cancelsubscription/success'),
  resumesubscriptionsuccess: path(ROOTS_DASHBOARD, '/account/resumesubscription/success'),
};
export const PATH_ONBOARDING = {
  welcome: path(ROOTS_ONBOARDING, '/welcome'),
  fencelist: path(ROOTS_ONBOARDING, '/fence'),
  fenceDetail: (id) => path(ROOTS_ONBOARDING, `/fence/${id}`),
  download: path(ROOTS_ONBOARDING, '/download'),
  tutorial: path(ROOTS_ONBOARDING, '/tutorial'),
};
export const PATH_MANAGEFENCE = {
  addFence: path(ROOTS_MANAGEFENCE, '/add'),
  requestFence: path(ROOTS_MANAGEFENCE, '/request'),
  removeFence: path(ROOTS_MANAGEFENCE, '/remove'),
  viewFence: (id) => path(ROOTS_MANAGEFENCE, `/view/${id}`),
};
