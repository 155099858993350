import PropTypes from 'prop-types';
import React, { useState } from 'react';
// @mui
import { Box, Stack, Typography, Button, IconButton, Grid, Dialog } from '@mui/material';
// hooks
import Iconify from '../../components/iconify';
// -------------------------------------------------------------------------------------

export default function SubHeader() {
  const [funModeOpen, setFunModeOpen] = useState(false);

  const handleFunOpen = () => {
    setFunModeOpen(true);
  };

  const handleFunClose = () => {
    setFunModeOpen(false);
  };

  return (
    <Box
      gap={1}
      display="grid"
      sx={{
        width: 1,
        backgroundColor: '#1FA9FF',
        height: { xs: '36px', sm: '48px' },
      }}
    >
      <Stack
        direction="row"
        sx={{
          flexDirection: {},
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            color: '#FFFFFF',
            fontWeight: 500,
            fontSize: { xs: '16px', sm: '26px' },
            textShadow: '0px 4px 8px rgba(38, 50, 56, 0.08), 0px 2px 4px rgba(38, 50, 56, 0.16)',
            alignSelf: 'center',
          }}
        >
          Build a fence now with
        </Typography>
        <Button
          onClick={handleFunOpen}
          sx={{
            py: 0,
            px: { xs: 0.5, sm: 1 },
            minWidth: 0,
          }}
        >
          <Typography
            sx={{
              color: '#FFFFFF',
              fontWeight: 500,
              fontSize: { xs: '16px', sm: '26px' },
              textShadow: '0px 4px 8px rgba(38, 50, 56, 0.08), 0px 2px 4px rgba(38, 50, 56, 0.16)',
            }}
          >
            <span style={{ borderBottom: 'solid 2px #ffffff', padding: 1 }}>Fun Mode</span>!
          </Typography>
        </Button>
      </Stack>

      <Dialog open={funModeOpen} maxWidth="md" fullWidth onClose={handleFunClose}>
        <Stack sx={{ px: { lg: 4, xs: 1, md: 2 }, pt: 2, pb: 4 }}>
          <Grid item sx={{ alignSelf: { xs: 'end', md: 'end' } }}>
            <IconButton color="inherit" edge="start" onClick={handleFunClose}>
              <Iconify icon="carbon:close-filled" style={{ color: '1FA9FF' }} />
            </IconButton>
          </Grid>
          <iframe
            src="https://realityfencefunmode.lpages.co/realityfence-fun-modde/"
            width="100%"
            height="790px"
            title="Fun Mode"
          />
        </Stack>
      </Dialog>
    </Box>
  );
}

TimeBlock.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

function TimeBlock({ label, value }) {
  const paddedValue = String(value).padStart(2, '0');
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: { xs: '5px', sm: '5px', lg: '7px' },
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          marginLeft: { xs: '5px' },
          justifyContent: 'center',
        }}
      >
        {String(paddedValue)
          .split('')
          .map((char, index) => (
            <Box
              key={index}
              sx={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: { xs: '15px', sm: '42px', lg: '42px' },
                height: { xs: '20px', sm: '53px', lg: '53px' },
                borderRadius: '5px',
                border: '0.5px solid rgba(255, 255, 255, 0.5)', // set border color opacity
                background:
                  'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.10) 100%)', // set background color opacity
                backdropFilter: 'blur(32.5px)',
                fontWeight: 'bold',
                mx: '1px', // add some margin to separate the characters
              }}
            >
              <Box
                sx={{
                  color: 'white', // make the char white
                  fontFamily: 'Digital-7 Regular',
                  fontSize: { xs: '15px', sm: '34px', lg: '34px' },
                  fontWeight: 100,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                {char}
              </Box>
            </Box>
          ))}
      </div>
      <Box
        sx={{
          color: 'white',
          fontSize: { xs: '12px', lg: '15px' },
          fontWeight: 100,
        }}
      >
        {label}
      </Box>
    </div>
  );
}
