import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import {
  Box,
  Grid,
  Link,
  Stack,
  Container,
  Typography,
  Button,
  useMediaQuery,
} from '@mui/material';
// routes
import { LINKS, ITEMS } from './config-footer';

import instagramImage from '../../../assets/images/illustrations/footer/Icon_instagram.png';
import facebookImage from '../../../assets/images/illustrations/footer/Icon_facebook.png';
import twitterImage from '../../../assets/images/illustrations/footer/Icon_twitter.png';
import linkedinImage from '../../../assets/images/illustrations/footer/Icon_linkedin.png';
import youtubeImage from '../../../assets/images/illustrations/footer/Icon_youtube.png';
import tiktokImage from '../../../assets/images/illustrations/footer/Icon_tiktok.png';
import whitelogoImage from '../../../assets/images/illustrations/whitelogo.png';
//
Footer.propTypes = {
  variant: PropTypes.string,
};

// ---------------------------------------------------------------------

export default function Footer({ variant }) {
  const isMobile = useMediaQuery('(max-width:600px)');

  const mainFooter = (
    <Box
      gap={1}
      display="grid"
      sx={{
        backgroundColor: '#1FA9FF',
      }}
    >
      <Container sx={{ textAlign: '-webkit-center', mt: 3 }}>
        <Grid
          container
          justifyContent={{
            xs: 'center',
            sm: 'space-between',
          }}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          <Grid item xs={12} sm={4.5} md={2.6}>
            {!isMobile && (
              <Stack sx={{ placeItems: 'center' }}>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '205px',
                    height: '86px',
                  }}
                >
                  <img src={whitelogoImage} alt="RF_LOGO" />
                </Box>
              </Stack>
            )}
            <Stack
              sx={{
                display: 'flex',
                flexDirection: { xs: 'row', sm: 'column' },
                alignItems: 'center',
                gap: { lg: 4, md: 4, xs: 2 },
                justifyContent: 'space-between',
                mt: { sm: 5, xs: 9 },
              }}
            >
              {ITEMS.map((item, index) => (
                <Button
                  key={index}
                  sx={{
                    width: { xs: '104px', sm: '168px' },
                    height: { xs: '45px', sm: '51px' },
                    backgroundColor: '#ffffff !important',
                    color: 'black',
                    fontSize: { md: '16px', xs: '14px' },
                    borderRadius: '31px',
                  }}
                  href={item.path}
                >
                  {item.name}
                </Button>
              ))}
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            md={8.2}
            sx={{
              pt: { xs: '30px', sm: '8px' },
              marginBottom: 5,
              display: 'flex',
              flexDirection: { md: 'row', xs: 'column' },
            }}
          >
            <Grid item xs={12}>
              <Stack
                display="grid"
                gridTemplateColumns={{
                  md: 'repeat(3,1fr)',
                  xs: 'repeat(2, 1fr)',
                }}
                column-gap="15px"
                ml={{ xs: '8px' }}
              >
                {LINKS.map((link, index) => (
                  <Stack
                    key={index}
                    items={link.children}
                    alignItems={{ placeItems: 'start' }}
                    gap={{ md: '30px', xs: '25px' }}
                  >
                    <Typography
                      component="div"
                      color="#ffffff"
                      fontSize="21px"
                      fontWeight="700"
                      marginTop="20px"
                    >
                      {link.headline}
                    </Typography>

                    {link.children.map((item) => (
                      <a key={item.name} href={item.path} style={{ color: '#ffffff' }}>
                        <Typography sx={{ fontSize: '16px', cursor: 'pointer' }}>
                          {item.name}
                        </Typography>
                      </a>
                    ))}
                  </Stack>
                ))}
                <Stack spacing="28px" alignItems={{ lg: 'flex-start', placeItems: 'start' }}>
                  <Typography
                    component="div"
                    color="#ffffff"
                    fontSize="21px"
                    fontWeight="700"
                    paddingTop={{ md: '20px', xs: '45px' }}
                  >
                    Contact
                  </Typography>
                  <Link component={RouterLink} color="#ffffff" fontSize="16px">
                    <Typography textAlign="start" sx={{ marginRight: '-20px' }}>
                      Extend Your Reality LLC
                      <br /> 6689 Orchard Lake Rd #245
                      <br /> West Bloomfield MI, 48322
                      <br /> United States of America
                    </Typography>
                  </Link>
                  <Stack>
                    <Typography textAlign="start" sx={{ marginRight: '-20px', color: '#ffffff' }}>
                      <a href="tel:2489857575">248-985-7575</a>
                    </Typography>
                    <Typography textAlign="start" sx={{ marginRight: '-20px', color: '#ffffff' }}>
                      <a href="mailto:contact@realityfence.com">contact@realityfence.com</a>
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        <Stack
          flexDirection="row"
          sx={{
            width: { sx: '90%', md: '50%' },
            mt: { xs: 3, sm: 14 },
            mb: { xs: 16, sm: 12 },
            px: { xs: 2, md: 0 },
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <a href="https://twitter.com/realityfence?s=21&t=MHMn60CoWkdnYSXH5GeIxA">
            <img src={twitterImage} style={{ height: isMobile ? '27px' : '45px' }} alt="twitter" />
          </a>

          <a href="https://www.facebook.com/profile.php?id=100089795147096&mibextid=LQQJ4d">
            <img
              src={facebookImage}
              style={{ height: isMobile ? '27px' : '45px' }}
              alt="facebook"
            />
          </a>

          <a href="https://www.linkedin.com/company/realityfence/">
            <img
              src={linkedinImage}
              style={{ height: isMobile ? '27px' : '45px' }}
              alt="linkedin"
            />
          </a>

          <a href="https://youtube.com/@realityfence">
            <img src={youtubeImage} style={{ height: isMobile ? '27px' : '45px' }} alt="youtube" />
          </a>

          <a href="https://instagram.com/realityfence?igshid=OGQ5ZDc2ODk2ZA==">
            <img
              src={instagramImage}
              style={{ height: isMobile ? '27px' : '45px' }}
              alt="Instagram"
            />
          </a>

          <a href="https://www.tiktok.com/@realityfence?_t=8eEN01ILXig&_r=1">
            <img src={tiktokImage} style={{ height: isMobile ? '27px' : '45px' }} alt="tiktok" />
          </a>
        </Stack>
        <hr style={{ paddingBottom: '150px' }} />
      </Container>
    </Box>
  );

  return mainFooter;
}
