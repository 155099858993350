import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
// @mui
import { Stack, Slider } from '@mui/material';
// utils
// hooks
import useResponsive from '../../../hooks/useResponsive';
// config
import { HeaderConfig } from './config-header';
// ----------------------------------------------------------------------

const ONBOARDING_STEP_URLS = [
  { path: '/welcome', value: 2 },
  { path: '/fence', value: 33 },
  { path: '/download', value: 66 },
  { path: '/tutorial', value: 98 },
];

Header.propTypes = {
  sx: PropTypes.object,
};

export default function Header({ sx }) {
  const [stepValue, setStepValue] = useState(0);
  const isDesktop = useResponsive('up', 'lg');

  const { pathname } = useLocation();
  useEffect(() => {
    ONBOARDING_STEP_URLS.forEach((url) => {
      if (pathname.indexOf(url.path) > -1) {
        setStepValue(url.value);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Stack sx={{ ...sx }}>
      {isDesktop && (
        <>
          <Stack flexDirection="row" justifyContent="space-between" height="40px">
            {HeaderConfig.map((index) => (
              <p
                style={{
                  fontSize: '25px',
                  fontWeight: 'bold',
                }}
                key={index}
              >
                {index}
              </p>
            ))}
          </Stack>
          <Stack>
            <Slider
              disabled
              size="middle"
              style={{ color: '#1FA9FF' }}
              value={stepValue}
              aria-labelledby="continuous-slider"
            />
          </Stack>
        </>
      )}
      {!isDesktop && (
        <Slider
          disabled
          size="middle"
          style={{ color: '#1FA9FF' }}
          value={stepValue}
          aria-labelledby="continuous-slider"
        />
      )}
    </Stack>
  );
}
