import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import subscriptionReducer from './slices/subscription';
import mediaReducer from './slices/media';
import mediatutorialReducer from './slices/mediatutorial';
import categoryReducer from './slices/category';
import tagsReducer from './slices/tags';
import edReducer from './slices/ed';
import qaReducer from './slices/qa';
import blogReducer from './slices/blog';
import billingReducer from './slices/billing';
import affiliateReducer from './slices/affiliate';
import fenceReducer from './slices/fence';
import userReducer from './slices/user';
import productReducer from './slices/product';

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: [
    'favorite_list',
    'request_list',
    'selected_list',
    'selectedFences',
    'removed_list',
    'new_favorite_list',
    'new_request_list',
    'new_selected_list',
  ],
};

export const fencePersistConfig = {
  key: 'fence',
  storage,
  keyPrefix: 'redux-',
  whitelist: [
    'category',
    'categoryTitle',
    'selected_fence_list',
    'new_selected_fence_list',
    'requested_fence_list',
    'new_requested_fence_list',
    'removed_fence_list',
  ],
};
const rootReducer = combineReducers({
  subscription: subscriptionReducer,
  product: persistReducer(productPersistConfig, productReducer),
  media: mediaReducer,
  mediatutorial: mediatutorialReducer,
  category: categoryReducer,
  tag: tagsReducer,
  ed: edReducer,
  qa: qaReducer,
  blog: blogReducer,
  billing: billingReducer,
  affiliate: affiliateReducer,
  fence: persistReducer(fencePersistConfig, fenceReducer),
  user: userReducer,
});

export default rootReducer;
