import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
// @mui
import {
  Button,
  Badge,
  Typography,
  Popover,
  Box,
  Stack,
  Grid,
  useMediaQuery,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { paramCase } from 'change-case';
// utils
//
import Request from '../../../assets/images/managefence/request.svg';
import Select from '../../../assets/images/managefence/select.svg';
import Trash from '../../../assets/images/managefence/trash.png';
import NoImage from '../../../assets/images/managefence/noimage.png';

import { PATH_ONBOARDING, PATH_MANAGEFENCE } from '../../../routes/paths';
import { setUserFenceData } from '../../../redux/slices/fence';
// components
import Image from '../../../components/image';
import { RFButton } from '../../../components/button';
import Iconify from '../../../components/iconify';
// ----------------------------------------------------------------------
const PATHS = [
  {
    id: 0,
    title: 'Add Fences',
    path: PATH_MANAGEFENCE.addFence,
  },
  {
    id: 1,
    title: 'Remove Fences',
    path: PATH_MANAGEFENCE.removeFence,
  },
  {
    id: 2,
    title: 'Request Fences',
    path: PATH_MANAGEFENCE.requestFence,
  },
];
// ----------------------------------------------------------------------
ToolBar.propTypes = {
  sx: PropTypes.object,
};
export default function ToolBar({ sx }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:550px)');
  const { new_selected_fence_list, new_requested_fence_list, removed_fence_list } = useSelector(
    (state) => state.fence
  );

  const [click, setCLick] = useState(null);
  const [anchorEl, setAnchorEl] = useState(false);

  const [popOverData, setPopOverData] = useState({
    title: 'Added to My Fences',
    fences: new_selected_fence_list,
  });

  const { pathname } = useLocation();

  const handlePopoverClose = () => {
    setCLick(null);
  };

  const handlePopoverClick = (event) => {
    setCLick(event.currentTarget);
  };

  const handleClickToolbar = (id) => {
    navigate(PATH_ONBOARDING.onboarding.viewDetail(paramCase(id)));
  };

  const handleItemDelete = (itemId) => {
    const fenceData = popOverData.fences.filter((item) => item.id !== itemId);
    switch (popOverData.title) {
      case 'Added to My Fences':
        dispatch(
          setUserFenceData({
            selected: fenceData,
            requested: new_requested_fence_list,
            removed: removed_fence_list,
          })
        );
        setPopOverData({ ...popOverData, fences: fenceData });

        break;
      case 'Added to My Requests':
        dispatch(
          setUserFenceData({
            selected: new_selected_fence_list,
            requested: fenceData,
            removed: removed_fence_list,
          })
        );
        setPopOverData({ ...popOverData, fences: fenceData });
        break;
      default:
        break;
    }
  };

  const onHandleMenuItem = (event) => {
    setAnchorEl(event.currentTarget); // Toggle the value of showButtons
  };

  const onHandleClose = () => {
    setAnchorEl(null);
  };

  const itemContent = (
    <Stack
      sx={{
        gap: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack
        className="step6"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button
          style={{
            backgroundColor: '#FFFFFF',
            height: '50px',
            borderRadius: '14px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          }}
          onClick={(event) => {
            setPopOverData({
              title: 'Added to My Requests',
              fences: new_requested_fence_list,
            });
            handlePopoverClick(event);
          }}
        >
          <Badge badgeContent={new_requested_fence_list.length} color="primary">
            <img src={Request} alt="request" />
          </Badge>
        </Button>
        <Typography fontSize="12px" color="black">
          Requests
        </Typography>
      </Stack>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button
          style={{
            backgroundColor: '#FFFFFF',
            height: '50px',
            borderRadius: '14px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          }}
          onClick={(event) => {
            setPopOverData({
              title: 'Added to My Fences',
              fences: new_selected_fence_list,
            });
            handlePopoverClick(event);
          }}
        >
          <Badge badgeContent={new_selected_fence_list.length} color="success">
            <img src={Select} alt="select" />
          </Badge>
        </Button>
        <Typography fontSize="12px" color="black">
          My Fences
        </Typography>
      </Stack>
    </Stack>
  );

  const renderContent = (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        gap: 1.2,
        p: '4px 16px',
        backgroundColor: '#F5F5F5',
      }}
    >
      {isMobile ? (
        <>
          <IconButton sx={{ mr: 1, color: 'text.primary' }} onClick={onHandleMenuItem}>
            <Iconify icon="eva:menu-2-fill" style={{ transform: 'rotate(90deg)' }} />
          </IconButton>

          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onHandleClose}>
            {PATHS.filter((item) => item.path !== pathname).map((item) => (
              <MenuItem
                key={item.id}
                onClick={() => {
                  window.location.href = item.path;
                }}
              >
                {item.title}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" gap={2}>
          {PATHS.map((item) => (
            <RFButton
              key={item.id}
              sx={{
                height: '50px',
                fontSize: 13,
                width: '100px',
                lineHeight: '1.2',
                ...(pathname !== item.path && {
                  backgroundColor: '#ffffff !important',
                  color: '#000000',
                }),
              }}
              onClick={() => {
                window.location.href = item.path;
              }}
            >
              {item.title}
            </RFButton>
          ))}
        </Stack>
      )}

      <Stack>{itemContent}</Stack>
    </Stack>
  );

  return (
    <Stack sx={{ ...sx }}>
      {renderContent}

      <Popover
        open={Boolean(click)}
        anchorEl={click}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box flexDirection="column" sx={{ p: 2, width: { xs: 300, sm: 400 } }}>
          <Stack flexDirection="row" justifyContent="space-between">
            <Typography variant="h5" gutterBottom>
              {popOverData.title}
            </Typography>
            <Badge badgeContent={popOverData.fences.length} color="primary">
              <img src={Select} className="w-[20px] h-[20px]" alt="favour" />
            </Badge>
          </Stack>
          <Stack flexDirection="column" spacing={2}>
            {popOverData.fences.map((fence, index) => (
              <Box
                sx={{
                  boxShadow: '0px 6px 9px #a6a6a6',
                  alignSelf: 'center',
                  width: { xs: '250px', sm: '350px' },
                  px: 1.5,
                  py: 2,
                }}
                key={index}
              >
                <Grid
                  container
                  item
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignSelf: 'center',
                  }}
                >
                  <Grid item xs={9.6}>
                    <Grid
                      item
                      container
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignSelf: 'center',
                      }}
                    >
                      <Grid item xs={3.5} sx={{ alignSelf: 'center' }}>
                        <Image
                          src={
                            fence?.filesImage && fence.filesImage.length > 0
                              ? fence.filesImage[0].preview
                              : NoImage
                          }
                          className="w-[70px] cursor-pointer"
                          alt="favour"
                          onClick={() => handleClickToolbar(fence && fence.id)}
                        />
                      </Grid>
                      <Grid item xs={8} sx={{ alignSelf: 'center' }}>
                        <Typography
                          sx={{ fontSize: '16px', fontWeight: 700, wordBreak: 'break-word' }}
                        >
                          {fence?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={2} sx={{ alignSelf: 'center' }}>
                    <Button onClick={() => handleItemDelete(fence && fence.id)}>
                      <img src={Trash} className="w-[20px] h-[20px]" alt="trash" />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Stack>
        </Box>
      </Popover>
    </Stack>
  );
}
