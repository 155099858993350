import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { Selectable } from '../../utils/constants';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';

const initialState = {
  isloading: false,
  error: null,
  fence: null,
  fences: [],
  drafts: [],
  category: [],
  categoryTitle: '',
  fences_count: 0,
  selected_count: 0,
  selectableElements: [],
  selectable: 2,
  count: 0,

  // New Layout States
  selected_fence_list: [],
  new_selected_fence_list: [],
  requested_fence_list: [],
  new_requested_fence_list: [],
  removed_fence_list: [],
};

const slice = createSlice({
  name: 'fence',
  initialState,
  reducers: {
    startLoading(state) {
      state.isloading = true;
    },

    hasError(state, action) {
      state.isloading = false;
      state.error = action.payload;
    },

    getFencesSuccess(state, action) {
      state.isloading = false;
      state.fences = action.payload;
    },

    getDraftsSuccess(state, action) {
      state.isloading = false;
      state.drafts = action.payload;
    },

    getFenceSuccess(state, action) {
      state.isloading = false;
      state.fence = action.payload;
    },

    setSelectable(state, action) {
      state.isloading = false;
      state.selectable = action.payload;
    },
    setCategoryTitle(state, action) {
      state.isloading = false;
      state.categoryTitle = action.payload;
    },
    setSelectableElements(state, action) {
      state.isloading = false;
      state.selectableElements = action.payload;
    },

    getCategorySuccess(state, action) {
      state.isloading = false;
      state.category = action.payload.category;
      state.fences_count = action.payload.productCount;
      state.selected_count = action.payload.selectedCount;
    },

    setCurrentFenceDataSuccess(state, action) {
      state.isloading = false;
      state.selected_fence_list = action.payload.selected || [];
      state.requested_fence_list = action.payload.requested || [];
    },

    setFenceData(state, action) {
      state.isloading = false;
      state.new_selected_fence_list = action.payload.selected || [];
      state.new_requested_fence_list = action.payload.requested || [];
      state.removed_fence_list = action.payload.removed || [];
    },
  },
});

export default slice.reducer;

export const {
  getFencesSuccess,
  getDraftsSuccess,
  getFenceSuccess,
  setCurrentFenceDataSuccess,
  setFenceData,
} = slice.actions;

export const getFences = () => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get('/products');
    dispatch(slice.actions.getFencesSuccess(response.data));
    dispatch(slice.actions.getDraftsSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const getFence = (id) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(`/products/${id}`);
    dispatch(slice.actions.getFenceSuccess(response.data.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const getFenceByid = (fenceId) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(`/products/${fenceId}`);
    dispatch(slice.actions.getFenceSuccess(response.data.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const getVisibleFences = (params) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(`/products/getFences/visible?${params}`);
    switch (response.data.code) {
      case 200:
        dispatch(slice.actions.getFencesSuccess(response.data));
        break;
      case 500:
        dispatch(slice.actions.hasError(response.data.message));
        break;
      default:
        break;
    }
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const getFenceFilter = (keyword) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(`/products?keyword=${keyword}`);
    dispatch(slice.actions.getFencesSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const updateFence = (id, fenceData, Snackbar, navigate) => async (dispatch) => {
  axios
    .put(`products/${id}`, fenceData)
    .then((res) => {
      navigate(PATH_DASHBOARD.general.fileManager);
      Snackbar('Successfully updated fence', 'success');
    })
    .catch((err) => {
      dispatch(slice.actions.hasError(err));
      Snackbar('Failed to update fence', 'error');
    });
};

export const getFencesByStyleTag = (styles, tags) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post('/products/getByStyleTag', {
      styles,
      tags,
    });
    dispatch(slice.actions.setCategoryTitle('Fences'));
    dispatch(slice.actions.setSelectable(Selectable.Fence));
    dispatch(slice.actions.setSelectableElements(response.data.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const getSearchFencesByName = (fileId) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(`/products/findby/${fileId}`);
    dispatch(slice.actions.setSelectableElements(response.data.data.product));
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error; // Add this line to throw the error and return it
  }
};

export const getSelectableFences = (selectable, params, category) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response =
      selectable === Selectable.Fence
        ? await axios.post('/products/getCategories', params)
        : await axios.get('/category');
    dispatch(
      slice.actions.setCategoryTitle(selectable === Selectable.Fence ? category : 'Categories')
    );
    dispatch(slice.actions.setSelectable(selectable));
    dispatch(slice.actions.setSelectableElements(response.data.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export function getCategories() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/category`);
      dispatch(slice.actions.getCategorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const getCurrnetUserFenceData = (userId) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const res = await axios.get(`/products/getRequest/${userId}`);
    const resData = res.data.data;
    dispatch(
      setCurrentUserFenceData({
        selected: resData.selectedlist,
        requested: resData.requestlist,
      })
    );
  } catch (err) {
    dispatch(slice.actions.hasError(err));
  }
};

export const setUserFenceData = (fenceData) => (dispatch) => {
  dispatch(slice.actions.setFenceData(fenceData));
};

export const setCurrentUserFenceData = (fenceData) => (dispatch) => {
  dispatch(slice.actions.setCurrentFenceDataSuccess(fenceData));
};
